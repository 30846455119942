<template>
  <div class="section">
    <div>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex flex-column justify-content-around align-items-sm-start">
          <div>
            <h1 class="head-title">РАБОТА</h1>
            <h1 class="head-subtitle">В РАДОСТЬ!</h1>
          </div>
          <p class="head-slogan">Работа — часть нашей жизни. Поэтому <br>
            мы создаем такие условия, чтобы <br>
            работа в нашей компании нравилась <br>
            каждому сотруднику.</p>
          <router-link class="button d-none d-md-inline" :to="city ? `/questionnaire?city=${city}` : '/questionnaire'">Заполнить анкету</router-link>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div>
            <div class="row">
              <div class="col-6">
                <div class="head-image-container head-image_1" :class="{'head-image_notViewed': !imageViewed[0]}" @click="select(0)">
                  <img src="@/assets/img/head-1.webp" alt="">
                </div>
                <div class="head-image-container head-image_2" :class="{'head-image_notViewed': !imageViewed[1]}" @click="select(1)">
                  <img src="@/assets/img/head-2.webp" alt="">
                </div>
              </div>
              <div class="col-6">
                <div class="head-image-container head-image_3" :class="{'head-image_notViewed': !imageViewed[2]}" @click="select(2)">
                  <img src="@/assets/img/head-3.webp" alt="">
                </div>
                <div class="head-image-container head-image_4" :class="{'head-image_notViewed': !imageViewed[3]}" @click="select(3)">
                  <img src="@/assets/img/head-4.webp" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row d-md-none d-flex justify-content-center">
        <router-link class="button" :to="city ? `/questionnaire?city=${city}` : '/questionnaire'">Заполнить анкету</router-link>
      </div>
    </div>
    <PopUpVideo @changeIndexVideo="changeIndexVideo" :show="show" ref="popup" @close="close" :indexVideo="currentVideoIndex" :videos="videos">
    </PopUpVideo>
  </div>


</template>

<script>

const video1 = () =>  import('@/assets/video/Юлия менеджер.mp4')
const video2 = () => import('@/assets/video/Женя Курьер 9х16.mp4')
const video3 = () => import('@/assets/video/Ольга кассир.mp4')
const video4 = () => import('@/assets/video/Арина Пиццамейкер 9х16.mp4')

export default {
  name: "Head",
  data(){
    return{
      show: false,
      currentVideoSrc: '',
      currentVideoIndex: 0,
      videos: [],
      imageViewed: {
        0: false,
        1: false,
        2: false,
        3: false
      }
    }
  },
  watch: {
    show(newValue, oldValue){
      if (newValue){
        // document.body.style.position = 'fixed';
        document.body.style.height = '100vh'
        document.body.style.overflow = 'hidden'
        document.body.style.top = `-${window.scrollY}px`;
      } else {
        document.body.style.height = 'auto'
        document.body.style.overflow = 'scroll'
      }
    }
  },
  components: {},
  methods: {
    changeIndexVideo(index){
      this.currentVideoIndex = index
      this.setVideoViewed(index)
    },
    setVideoViewed(index){
      this.imageViewed[index] = true
    },
    showPopUp(){
      this.show = true
    },
    close(value){
      // this.playerVideo.pause()
      this.show = value
    },
    select(index) {
      this.setVideoViewed(index)
      this.currentVideoIndex = index
      this.show = true
    }
  },

  props: ['city']
}
</script>

<style src="./Head.scss" lang="scss" scoped />

