<template>
  <div class="section">
    <div class="row flex-column-reverse flex-sm-row">
      <div class="col-sm-6 col-md-7 col-lg-7">
        <h1>ПРИСОЕДИНЯЙСЯ К НАШЕЙ КОМАНДЕ!</h1>
        <p>Мы рады всем, кому работа в радость! В нашем коллективе всегда готовы помочь и обучить. Предыдущий опыт не важен.</p>
        <div class="d-flex  justify-content-center justify-content-sm-start button_container">
          <router-link class="button" :to="city ? `/questionnaire?city=${city}` : '/questionnaire'">Заполнить анкету</router-link>
        </div>
      </div>
      <div class="col-sm-6 col-md-5 col-lg-5">
        <img src="@/assets/img/JoinUs.webp" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JoinUs",
  props: ['city']
}
</script>

<style src="./JoinUs.scss" lang="scss" scoped />





