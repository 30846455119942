import { fabric } from 'fabric'
import logger from "@fortawesome/vue-fontawesome/src/logger";

import $ from 'jquery'

export default class Canvas {

   configLinesPc = [
        {
            points: [2, 767, 2, 636],
            stroke: '#CCCCCC',
            strokeWidth: 2,
        },
        {
            points: [145, 700, 145, 429],
            stroke: '#CCCCCC',
            strokeWidth: 2,
        },
        {
            points: [338, 534, 338, 296],
            stroke: '#CCCCCC',
            strokeWidth: 2,
        },
        {
            points: [568, 474, 568, 234],
            stroke: '#CCCCCC',
            strokeWidth: 2,
        },
        {
            points: [698, 441, 698, 150],
            stroke: '#CCCCCC',
            strokeWidth: 2,
        },
        {
            points: [812, 399, 812, 110],
            stroke: '#CCCCCC',
            strokeWidth: 2,
        },
        {
            points: [972, 298, 972, 40],
            stroke: '#CCCCCC',
            strokeWidth: 2,
        },
    ]
   configTextsPc = [
    {
        x: 0,
        y: 0,
        text: 'Старт',
        fontSize: 26,
        fontFamily: 'M PLUS Rounded 1c',
        fill: '#000',
        fontStyle: 'bold',
        fontWeight: 500,
    },
    {
        x: 18,
        y: 665,
        text: 'Стажёр',
        fontSize: 26,
        fontFamily: 'M PLUS Rounded 1c',
        fill: '#000',
        fontStyle: 'bold',
        fontWeight: 500,
        tooltipData: {
            position: 1,
            role:'Стажёр',
            description: 'Обучается и развивается с нуля',
            positionArrow: 'top'
        }

    },
    {
        x: 161,
        y: 425,
        text: 'Кандидат',
        fontSize: 26,
        fontFamily: 'M PLUS Rounded 1c',
        fill: '#000',
        fontStyle: 'bold',
        fontWeight: 500,
        tooltipData: {
            position: 2,
            role:'Кандидат',
            description: 'Работает в команде и совершенствует навыки работы на кухне или кассе',
            positionArrow: 'top'

        }

    },
    {
        x: 161,
        y: 475,
        text: 'Сборщик-\nупаковщик',
        fontSize: 26,
        fontFamily: 'M PLUS Rounded 1c',
        fill: '#000',
        fontStyle: 'bold',
        fontWeight: 500,
        tooltipData: {
            position: 2,
            role:'Сборщик-упаковщик',
            description: 'Быстро и внимательно собирает заказы, отдает курьерам и помогает кассиру',
            positionArrow: 'top'
        }

    },
    {
        x: 161,
        y: 555,
        text: 'Клинер',
        fontSize: 26,
        fontFamily: 'M PLUS Rounded 1c',
        fill: '#000',
        fontStyle: 'bold',
        fontWeight: 500,
        tooltipData: {
            position: 2,
            role:'Клинер',
            description: 'Следит за чистотой на смене',
            positionArrow: 'top'
        }

    },
    {
        x: 161,
        y: 605,
        text: 'Курьер',
        fontSize: 26,
        fontFamily: 'M PLUS Rounded 1c',
        fill: '#000',
        fontStyle: 'bold',
        fontWeight: 500,
        tooltipData: {
            position: 2,
            role:'Курьер',
            description: 'Доставляет заказы',
            positionArrow: 'top'
        }

    },
    {
        x: 355,
        y: 294,
        text: 'Сушимейкер и \nпиццамейкер',
        fontSize: 26,
        fontFamily: 'M PLUS Rounded 1c',
        fill: '#000',
        fontStyle: 'bold',
        fontWeight: 500,
        tooltipData: {
            position: 3,
            role:'Сушимейкер и пиццамейкер',
            description: 'Вкусно готовят и стремятся стать инструкторами или менеджерами',
            positionArrow: 'bottom'
        }
    },
    {
        x: 355,
        y: 379,
        text: 'Кассир',
        fontSize: 26,
        fontFamily: 'M PLUS Rounded 1c',
        fill: '#000',
        fontStyle: 'bold',
        fontWeight: 500,
        tooltipData: {
            position: 3,
            role:'Кассир',
            description: 'Встречает гостей, дарит радость и хочет расти до менеджера',
            positionArrow: 'top'
        }
    },
    {
        x: 582,
        y: 232,
        text: 'Инструктор',
        fontSize: 26,
        fontFamily: 'M PLUS Rounded 1c',
        fill: '#000',
        fontStyle: 'bold',
        fontWeight: 500,
        tooltipData: {
            position: 4,
            role:'Инструктор',
            description: 'Долго и качественно работает  и обучает стажеров',
            positionArrow: 'bottom'
        }

    },
    {
        x: 712,
        y: 148,
        text: 'Менеджер',
        fontSize: 26,
        fontFamily: 'M PLUS Rounded 1c',
        fill: '#000',
        fontStyle: 'bold',
        fontWeight: 500,
        tooltipData: {
            position: 5,
            role:'Менеджер смены',
            description: 'Организует работу всей команды, лучше всех знает стандарты',
            positionArrow: 'bottom'
        }

    },
    {
        x: 712,
        y: 188,
        text: 'смены',
        fontSize: 26,
        fontFamily: 'M PLUS Rounded 1c',
        fill: '#000',
        fontStyle: 'bold',
        fontWeight: 500,
    },
    {
        x: 826,
        y: 108,
        text: 'Управляющий',
        fontSize: 26,
        fontFamily: 'M PLUS Rounded 1c',
        fill: '#000',
        fontStyle: 'bold',
        fontWeight: 500,
        tooltipData: {
            position: 6,
            role:'Управляющий',
            description: 'Следит за работой всего ресторана',
            positionArrow: 'bottom'
        }

    },
    {
        x: 986,
        y: 36,
        text: 'Владелец\nресторана',
        fontSize: 26,
        fontFamily: 'M PLUS Rounded 1c',
        fill: '#000',
        fontStyle: 'bold',
        fontWeight: 500,
        tooltipData: {
            position: 7,
            role:'Владелец ресторана',
            description: 'Партнер компании: успешно прошел путь от стажера до управляющего, набрался опыта, открыл свой ресторан по франшизе Япоки',
            positionArrow: 'bottom'
        }

    },
]
   configLinesMobile = [
        {
            points: [2, 560, 2, 439],
            stroke: '#CCCCCC',
            strokeWidth: 2,
        },
        {
            points: [132, 510, 132, 312],
            stroke: '#CCCCCC',
            strokeWidth: 2,
        },
        {
            points: [37, 545, 37, 386],
            stroke: '#CCCCCC',
            strokeWidth: 2,
        },
        {
            points: [160, 495, 160, 368],
            stroke: '#CCCCCC',
            strokeWidth: 2,
        },
        {
            points: [45, 526, 45, 371],
            stroke: '#CCCCCC',
            strokeWidth: 2,
        },
        {
            points: [158, 494, 158, 352],
            stroke: '#CCCCCC',
            strokeWidth: 2,
        },
        {
            points: [55, 495, 55, 424],
            stroke: '#CCCCCC',
            strokeWidth: 2,
        },
    ]
   configTextsMobile = [
        {
            x: 14,
            y: 420,
            text: 'Старт',
            fontSize: 16,
            fontFamily: 'M PLUS Rounded 1c',
            fill: '#000',
            fontStyle: 'bold',
            fontWeight: 500,
        },
        {
            x: 14,
            y: 470,
            text: 'Стажёр',
            fontSize: 16,
            fontFamily: 'M PLUS Rounded 1c',
            fill: '#000',
            fontStyle: 'bold',
            fontWeight: 500,
            tooltipData: {
                position: 1,
                role:'Стажёр',
                description: 'Обучается и развивается с нуля',
                positionArrow: 'top'
            }

        },
        {
            x: 144,
            y: 308,
            text: 'Кандидат',
            fontSize: 16,
            fontFamily: 'M PLUS Rounded 1c',
            fill: '#000',
            fontStyle: 'bold',
            fontWeight: 500,
            tooltipData: {
                position: 2,
                role:'Кандидат',
                description: 'Работает в команде и совершенствует навыки работы на кухне или кассе',
                positionArrow: 'top'

            }

        },
        {
            x: 144,
            y: 358,
            text: 'Сборщик-\nупаковщик',
            fontSize: 16,
            fontFamily: 'M PLUS Rounded 1c',
            fill: '#000',
            fontStyle: 'bold',
            fontWeight: 500,
            tooltipData: {
                position: 2,
                role:'Сборщик-упаковщик',
                description: 'Быстро и внимательно собирает заказы, отдает курьерам и помогает кассиру',
                positionArrow: 'top'
            }

        },
        {
            x: 144,
            y: 408,
            text: 'Клинер',
            fontSize: 16,
            fontFamily: 'M PLUS Rounded 1c',
            fill: '#000',
            fontStyle: 'bold',
            fontWeight: 500,
            tooltipData: {
                position: 2,
                role:'Клинер',
                description: 'Следит за чистотой на смене',
                positionArrow: 'top'
            }

        },
        {
            x: 144,
            y: 458,
            text: 'Курьер',
            fontSize: 16,
            fontFamily: 'M PLUS Rounded 1c',
            fill: '#000',
            fontStyle: 'bold',
            fontWeight: 500,
            tooltipData: {
                position: 2,
                role:'Курьер',
                description: 'Доставляет заказы',
                positionArrow: 'top'
            }

        },
        {
            x: 43,
            y: 383,
            text: 'Сушимейкер и \nпиццамейкер',
            fontSize: 16,
            fontFamily: 'M PLUS Rounded 1c',
            fill: '#000',
            fontStyle: 'bold',
            fontWeight: 500,
            tooltipData: {
                position: 3,
                role:'Сушимейкер и пиццамейкер',
                description: 'Вкусно готовят и стремятся стать инструкторами или менеджерами',
                positionArrow: 'bottom'
            }
        },
        {
            x: 43,
            y: 433,
            text: 'Кассир',
            fontSize: 16,
            fontFamily: 'M PLUS Rounded 1c',
            fill: '#000',
            fontStyle: 'bold',
            fontWeight: 500,
            tooltipData: {
                position: 3,
                role:'Кассир',
                description: 'Встречает гостей, дарит радость и хочет расти до менеджера',
                positionArrow: 'top'
            }
        },
        {
            x: 166,
            y: 365,
            text: 'Инструктор',
            fontSize: 16,
            fontFamily: 'M PLUS Rounded 1c',
            fill: '#000',
            fontStyle: 'bold',
            fontWeight: 500,
            tooltipData: {
                position: 4,
                role:'Инструктор',
                description: 'Долго и качественно работает  и обучает стажеров',
                positionArrow: 'bottom'
            }

        },
        {
            x: 57,
            y: 368,
            text: 'Менеджер',
            fontSize: 16,
            fontFamily: 'M PLUS Rounded 1c',
            fill: '#000',
            fontStyle: 'bold',
            fontWeight: 500,
            tooltipData: {
                position: 5,
                role:'Менеджер смены',
                description: 'Организует работу всей команды, лучше всех знает стандарты',
                positionArrow: 'bottom'
            }

        },
        {
            x: 57,
            y: 398,
            text: 'смены',
            fontSize: 16,
            fontFamily: 'M PLUS Rounded 1c',
            fill: '#000',
            fontStyle: 'bold',
            fontWeight: 500,
        },
        {
            x: 170,
            y: 348,
            text: 'Управляющий',
            fontSize: 16,
            fontFamily: 'M PLUS Rounded 1c',
            fill: '#000',
            fontStyle: 'bold',
            fontWeight: 500,
            tooltipData: {
                position: 6,
                role:'Управляющий',
                description: 'Следит за работой всего ресторана',
                positionArrow: 'bottom'
            }

        },
        {
            x: 67,
            y: 420,
            text: 'Владелец\nресторана',
            fontSize: 16,
            fontFamily: 'M PLUS Rounded 1c',
            fill: '#000',
            fontStyle: 'bold',
            fontWeight: 500,
            tooltipData: {
                position: 7,
                role:'Владелец ресторана',
                description: 'Партнер компании: успешно прошел путь от стажера до управляющего, набрался опыта, открыл свой ресторан по франшизе Япоки',
                positionArrow: 'bottom'
            }

        },
    ]

    constructor(refCanvas, parent, refTooltip = null) {
        this.refCanvas = refCanvas
        this.createCanvas()
        this.canvas.hoverCursor = 'default'
        this.parent = parent
        this.objectsPc = {
            texts: [],
            lines: [],
            paths: [
                new fabric.Path('M1.5 475L144 407H238L338.5 242L419 289.5L568 180L638.5 222.5L698.5 150.5L760.5 175.5L811.5 108L889 127L972 3.5L1053.5 129.5H1090.5L1116.5 151.5', {
                    lockMovementX: true,
                    lockMovementY: true,
                    selectable: false,
                    fill: 'transparent',
                    stroke: '#A42F84',
                    top:295,
                    'strokeWidth':3
                }),
                new fabric.Path('M0.500481 8.63851L39.3265 8.63852L53.4579 25.2383L91.0013 1.99988', {
                    lockMovementX: true,
                    lockMovementY: true,
                    selectable: false,
                    fill: '#EDEFF1',
                    stroke: 'black',
                    left: 522, top:482,
                    'strokeWidth':3
                }),
                new fabric.Path('M1.5 18.5L29.5 2L51 24.5L70 2L95 11', {
                    lockMovementX: true,
                    lockMovementY: true,
                    selectable: false,
                    fill: '#EDEFF1',
                    stroke: 'black',
                    left:925, top:335,
                    'strokeWidth':3
                }),
            ]
        }
        this.objectsMobile = {
            texts: [],
            lines: [],
            paths: [
                new fabric.Path('M1 70.5L133.5 20L232 20L245 1', {
                    lockMovementX: true,
                    lockMovementY: true,
                    selectable: false,
                    fill: 'transparent',
                    stroke: '#A42F84',
                    left: 0,
                    top:490,
                    'strokeWidth':3 }),
                new fabric.Path('M1.99998 100.5L38 50L89 89L161 2.5L274.5 30L284.5 23.5', {
                    lockMovementX: true,
                    lockMovementY: true,
                    selectable: false,
                    fill: 'transparent',
                    stroke: '#A42F84',
                    top:490,
                    'strokeWidth':3 }),
                new fabric.Path('M1.49998 62.5L46 35.5L104 57.5L159.5 2.5L282.5 30', {
                    lockMovementX: true,
                    lockMovementY: true,
                    selectable: false,
                    fill: 'transparent',
                    stroke: '#A42F84',
                    top:490,
                    'strokeWidth':3 }),
                new fabric.Path('M1.49997 131L56 3.50001L108 100L133 100L148.5 115', {
                    lockMovementX: true,
                    lockMovementY: true,
                    selectable: false,
                    fill: 'transparent',
                    stroke: '#A42F84',
                    top:490,
                    'strokeWidth':3 }),
                new fabric.Path('MM1 15.2L22.5615 2L39.1176 20L53.7487 2L73 9.2', {
                    lockMovementX: true,
                    lockMovementY: true,
                    selectable: false,
                    fill: '#EDEFF1',
                    stroke: 'black',
                    left:20, top:525,
                    'strokeWidth':3
                }),
            ]
        }
        this.createLines()
        this.createTexts()
        this.group = null
        this.offsetX = 0
        this.offsetY = 0
        this.tooltip = refTooltip
    }



    createCanvas(pc = false) {
        this.canvas = new fabric.Canvas(this.refCanvas, {
            allowTouchScrolling: true,
            controlsAboveOverlay: true,
        })

        this.canvas.selection = false;
        this.canvas.forEachObject(function(o) {
            o.selectable = false;
        });
    }

    createLines(){
        this.configLinesPc.forEach(lineConfig => {
            let line = new fabric.Line(lineConfig.points, {
                stroke: lineConfig.stroke,
                strokeWidth: lineConfig.strokeWidth,
                lockMovementX: true,
                lockMovementY: true,
                selectable: false,
            })
            this.objectsPc.lines.push(line)
        })
        this.configLinesMobile.forEach(lineConfig => {
            let line = new fabric.Line(lineConfig.points, {
                stroke: lineConfig.stroke,
                strokeWidth: lineConfig.strokeWidth,
                lockMovementX: true,
                lockMovementY: true,
                selectable: false,
            })
            this.objectsMobile.lines.push(line)
        })
    }

    createTexts() {
        this.configTextsPc.forEach((textConfig, textConfigIndex) => {
            if (textConfig.text !== 'Старт'){
                let text = new fabric.Text(textConfig.text, {
                    fontFamily: textConfig.fontFamily,
                    fontSize: textConfig.fontSize,
                    fontWeight: textConfig.fontWeight,
                    left: textConfig.x,
                    top: textConfig.y,
                    width: 220,
                    backgroundColor: '#EDEFF1',
                    padding: 4,
                    lockMovementX: true,
                    lockMovementY: true,
                    selectable: false,
                    id: textConfigIndex
                })
                this.objectsPc.texts.push(text)
            }

        })
        this.configTextsMobile.forEach((textConfig, textConfigIndex) => {
            let text = new fabric.Text(textConfig.text, {
                fontFamily: textConfig.fontFamily,
                fontSize: textConfig.fontSize,
                fontWeight: textConfig.fontWeight,
                left: textConfig.x,
                top: textConfig.y,
                width: 220,
                backgroundColor: '#EDEFF1',
                padding: 4,
                lockMovementX: true,
                lockMovementY: true,
                selectable: false,
                id: textConfigIndex
            })
            this.objectsMobile.texts.push(text)
        })
    }

    setFull(w = 0, h = 0){
        this.canvas.setWidth(this.parent.offsetWidth + w)
        this.canvas.setHeight(this.parent.offsetHeight + h)

    }



    renderPc() {

        this.setFull()
        this.canvas.clear()
        const rect = new fabric.Rect({
            width: 111,
            height: 39,
            top: 570,
            left: 0,
            rx: 18,
        })
        let text = new fabric.Text(this.configTextsPc[0].text, {
            fontFamily: this.configTextsPc[0].fontFamily,
            fontSize: this.configTextsPc[0].fontSize,
            fontWeight: 400,
            left: this.configTextsPc[0].x,
            top: this.configTextsPc[0].y,
            width: 220,
            backgroundColor: '#EDEFF1',
            padding: 4,
            lockMovementX: true,
            lockMovementY: true,
            selectable: false,
            id: 0
        })
        text.backgroundColor = '#A42F84'
        text.originY = 'center'
        text.originX = 'center'
        text.textAlign = 'center'
        const g = new fabric.Group([rect, text], {
            top: 0
        })
        g.item(0).set('fill', '#A42F84')
        g.item(1).set({
            fontWeight: 400,
            fill: 'white',
            left: 12,
            top: 293
        })
        this.group = new fabric.Group([
            g,
            ...this.objectsPc.lines, this.objectsPc.paths[0], ...this.objectsPc.texts
        ], {
            subTargetCheck: true,
            lockMovementX: true,
            lockMovementY: true,
            selectable: false,
            top: 0,
            left: 0
        })
        this.group.on('mouseover', (e) => {

            this.canvas.on('mouse:move', (e) =>
                {
                    if (e.subTargets && e.subTargets.length && e.subTargets[0].text){
                        if (e.subTargets[0].text !== 'Старт'){
                            let text = this.configTextsPc.find(text => {
                                let r = new RegExp(e.subTargets[0].text)
                                if (text.tooltipData && (r.test(text.tooltipData.role) || r.test(text.text))){
                                    return text
                                }
                            })
                            let tooltipTemplate = this.createTooltipTemplate(text?.tooltipData.position, text?.tooltipData.role, text?.tooltipData.description)
                            if (e.subTargets[0].text === 'Менеджер' || e.subTargets[0].text === 'смены'){
                                if (e.subTargets[0].text === 'Менеджер'){
                                    this.objectsPc.texts[9].set('fill', '#A42F84')
                                }
                                if (e.subTargets[0].text === 'смены'){
                                    this.objectsPc.texts[8].set('fill', '#A42F84')
                                }
                            }

                            e.subTargets[0].set('fill', '#A42F84')
                            this.tooltip.style.left = `${text?.x}px`
                            this.tooltip.style.visibility = `visible`
                            this.tooltip.classList.add(text.tooltipData.positionArrow === 'top' ? 'bs-tooltip-top' : 'bs-tooltip-bottom')
                            this.tooltip.childNodes[0].style.left = `${this.tooltip.childNodes[1].offsetWidth  / 2 - 16}px`
                            this.tooltip.childNodes[1].innerHTML = tooltipTemplate

                            this.tooltip.style.top = `${text.tooltipData.positionArrow === 'top' ? text?.y - 10 - this.tooltip.offsetHeight : text?.y + e.subTargets[0].height + 20}px`
                            if ( e.subTargets[0].text === 'Менеджер' || e.subTargets[0].text === 'смены'){
                                this.tooltip.style.top = `${text.tooltipData.positionArrow === 'top' ? text?.y - 10 - this.tooltip.offsetHeight : text?.y + e.subTargets[0].height + 49}px`
                                this.tooltip.style.left = `${text?.x - 10}px`
                                this.tooltip.childNodes[0].style.left = `${this.tooltip.childNodes[1].offsetWidth  / 2 - 16 - 10}px`
                            }

                            e.subTargets[0].on('mouseout', () => {
                                this.tooltip.style.top = `0`
                                this.tooltip.style.left = `0`
                                if (this.tooltip.classList.length > 3){
                                    this.tooltip.classList.remove(this.tooltip.classList[this.tooltip.classList.length - 1])
                                }
                                this.tooltip.style.visibility = `hidden`
                                if (e.subTargets[0].text === 'Менеджер' || e.subTargets[0].text === 'смены'){
                                    e.subTargets[0].text === 'Менеджер' ? this.objectsPc.texts[9].set('fill', '#000') : this.objectsPc.texts[8].set('fill', '#000')
                                }
                                e.subTargets[0].set('fill', '#000')
                                this.canvas.renderAll()
                            })
                            this.canvas.renderAll()
                        }
                    }
                }
            )

        })
        this.group.on('mouseout', () => {
            this.tooltip.style.visibility = `hidden`
            if (this.tooltip.classList.length > 3){
                this.tooltip.classList.remove(this.tooltip.classList[this.tooltip.classList.length - 1])
            }
            this.group._objects.filter(object => object.text).forEach(object => {
                object.set('fill', '#000')
            })
            this.canvas.renderAll()
        })
        this.canvas.add(this.group)
        this.canvas.renderAll()
    }


    groupScale(){
       // let scaleX = this.canvas.getWidth() / (this.group.width + this.offsetX)
       // scaleX = 1.12
       // let scaleY = 1
       // this.group.scaleX = scaleX
       // this.group.scaleY = scaleY

    }


    renderMobile(){
        this.canvas.removeListeners("touchstart", this.canvas._onTouchStart)
        this.setFull(0, +0)
        this.canvas.selectable = false
        this.canvas.clear()
        this.offsetX = 20
        this.offsetY = this.canvas.getHeight() / 2 + 100
        const rect = new fabric.Rect({
            width: 79,
            height: 39,
            top: 394,
            left: 0,
            rx: 18,
        })
        this.objectsMobile.texts[0].backgroundColor = '#A42F84'
        this.objectsMobile.texts[0].originY = 'center'
        this.objectsMobile.texts[0].originX = 'center'
        this.objectsMobile.texts[0].textAlign = 'center'
        const g = new fabric.Group([rect, this.objectsMobile.texts[0]])
        g.item(0).set('fill', '#A42F84')
        g.item(1).set({
            fontWeight: 400,
            fill: 'white',
            left: 2,
            top: 0
        })
        this.group = new fabric.Group([
            g,
           this.objectsMobile.lines[0],
           this.objectsMobile.lines[1],
           this.objectsMobile.paths[0],
           this.objectsMobile.texts[1],
           this.objectsMobile.texts[2],
           this.objectsMobile.texts[3],
           this.objectsMobile.texts[4],
           this.objectsMobile.texts[5]
        ], {
            subTargetCheck: true,
            lockMovementX: true,
            lockMovementY: true,
            selectable: false,
            left: 20
        })
        this.group.top = this.canvas.getHeight() - this.group.height - 2
        this.groupScale()
        this.canvas.add(this.group)
        this.canvas.renderAll()


    }

    renderMobile2(){
        this.canvas.removeListeners("touchstart", this.canvas._onTouchStart)
        // this.renderMobileMain()
        this.setFull(0, 0)
        this.canvas.selectable = false
        this.canvas.clear()
        this.offsetX = 0
        this.offsetY = this.canvas.getHeight() / 2 + 100
        this.group = new fabric.Group([
            this.objectsMobile.lines[2],
            this.objectsMobile.lines[3],
            this.objectsMobile.paths[1],
            this.objectsMobile.texts[6],
            this.objectsMobile.texts[7],
            this.objectsMobile.texts[8],
        ], {
            subTargetCheck: true,
            lockMovementX: true,
            lockMovementY: true,
            selectable: false,
        })
        this.group.top = this.canvas.getHeight() - this.group.height - 84
        this.groupScale()
        this.canvas.add(this.group)
        this.canvas.renderAll()

    }

    renderMobile3(){
        this.canvas.removeListeners("touchstart", this.canvas._onTouchStart)
        // this.renderMobileMain()
        this.setFull(0, 0)
        this.canvas.selectable = false
        this.canvas.clear()
        this.offsetX = 0
        this.offsetY = this.canvas.getHeight() / 2 + 100
        this.group = new fabric.Group([
            this.objectsMobile.lines[4],
            this.objectsMobile.lines[5],
            this.objectsMobile.paths[2],
            new fabric.Group([
                this.objectsMobile.texts[9],
                this.objectsMobile.texts[10]
            ]),
            this.objectsMobile.texts[11],
        ], {
            subTargetCheck: true,
            lockMovementX: true,
            lockMovementY: true,
            selectable: false,
        })
        this.group.top = this.canvas.getHeight() - this.group.height - 163
        this.groupScale()
        this.canvas.add(this.group)
        this.canvas.renderAll()

    }

    renderMobile4(){
        this.canvas.removeListeners("touchstart", this.canvas._onTouchStart)
        // this.renderMobileMain()
        this.setFull(0, 0)
        this.canvas.selectable = false
        this.canvas.clear()
        this.offsetX = 80
        this.offsetY = this.canvas.getHeight() / 2 - 100
        this.group = new fabric.Group([
            this.objectsMobile.lines[6],
            this.objectsMobile.paths[4],
            this.objectsMobile.paths[3],
            this.objectsMobile.texts[12],
        ], {
            subTargetCheck: true,
            lockMovementX: true,
            lockMovementY: true,
            selectable: false,
        })
        this.group.top = this.canvas.getHeight() - this.group.height - 194
        this.groupScale()
        this.canvas.add(this.group)
        this.canvas.renderAll()

    }



    rerender(){
        this.setFull()
        this.canvas.clear()
        this.groupScale()
        this.canvas.add(this.group)
        this.canvas.renderAll()
    }

    createTooltipTemplate(position, roles, description){
        return `  <div class="tooltipTemplate">
                  <span>${position} ступень</span>
                  <h2>
                    ${roles}
                  </h2>
                  <p>${description}</p>
                </div>`
    }

}

