<template>
  <div class="section">
    <p>Япоки — сеть технологичных ресторанов с фокусом на доставку. Место,
      где из простых стажеров вырастают менеджеры, управляющие и
      владельцы собственных ресторанов. Наши основные продукты – это
      роллы и пицца, а наши принципы – открытость и доброжелательность.
      Мы становимся все лучше благодаря нашим клиентам и нашим сотрудникам.
    </p>
    <p>Дальнейшее развитие невозможно без людей, которые готовы учиться,
      работать и развиваться. Поэтому мы находимся в постоянном поиске
      таких людей.
    </p>
  </div>
</template>

<script>
export default {
  name: "Info"
}
</script>

<style src="./Info.scss" lang="scss" scoped />
