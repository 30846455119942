import Vue from 'vue'
import App from './App.vue'
import components from "@/components";
import { BootstrapVue, IconsPlugin, VBTooltipPlugin   } from 'bootstrap-vue'
import VueCarousel from '@/components/Carousel'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueRouter from 'vue-router'
import Main from "@/components/Main";
const Questionnaire = () => import("@/components/Questionnaire/Questionnaire");
import { FormSelectPlugin } from 'bootstrap-vue'
import vSelect from "vue-select";
import axios from 'axios';
import VueAxios from 'vue-axios'
import VueMask from 'v-mask'
import 'bootstrap/dist/js/bootstrap.bundle.min'


Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component("v-select", vSelect);
for(const component in components){

    Vue.component(components[component].name, components[component])

}


Vue.use(VueAxios, axios)

Vue.use(VueCarousel);
Vue.use(FormSelectPlugin)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueRouter)
Vue.use(VueMask);


const router = new VueRouter({
    mode: 'history',
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: '/',
            component: Main,
            meta: { title: 'Работа Япоки' }
        },
        {
            path: '/questionnaire',
            component: Questionnaire,
            meta: { title: 'Анкета' }
        }
    ]
})

new Vue({
    el: '#app',
    router,
    render: h => h(App)
})