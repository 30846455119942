<template>
  <div class="banner">
    <div class="wrapper d-flex justify-content-center">
      <img class="d-none d-md-block" src="@/assets/logo.svg">
      <div class="phone d-block d-md-none">
        <a href="tel:8-800-200-200-7">
          <font-awesome-icon :icon="['fas', 'phone']" flip="horizontal" :style="{width: '23px', height: '23px'}" />
        </a>
      </div>
      <p class="d-none d-md-block">Ищем сотрудников кухни, кассиров, курьеров и менеджеров смены</p>
      <router-link class="button d-md-inline button-black" :to="city ? `/questionnaire?city=${city}` : '/questionnaire'">Заполнить анкету</router-link>
    </div>
  </div>
</template>

<script>

import { library } from '@fortawesome/fontawesome-svg-core'
import {faPhone} from "@fortawesome/free-solid-svg-icons";
// Then add it to library
library.add(faPhone)

export default {
  name: "Banner",
  props: ['city']
}
</script>

<style src="./Banner.scss" lang="scss" scoped />
