<template>
  <div class="questionnaire">
    <div v-if="!formSend" class="row">
      <div class="col-lg-7 col-12">
        <div class="row">
          <div class="col">
            <h1>
              Заполните анкету кандидата:
            </h1>
          </div>
        </div>
        <form @submit.prevent="submitForm">
          <div class="row mb-3">
            <div class="col-12 city">
              <v-select
                  v-model="city"
                  :options="cities"
                  placeholder="Город"
                  :searchable="false"
                  @close="v$.city.$touch()"
              />
              <div class="error" v-for="error in v$.city.$errors">{{error.$message}}</div>
            </div>
          </div>
          <div class="row g-4 mb-3">
            <div class="col-6">
              <div class="form-floating">
                <input class="form-control"
                       type="text"
                       placeholder="Имя"
                       id="firstName"
                       @blur="v$.name.$touch()"
                       v-model="name"
                >
                <label for="firstName">Имя</label>
              </div>
              <div class="error" v-for="error in v$.name.$errors">{{error.$message}}</div>

            </div>
            <div class="col-6">
              <div class="form-floating">
                <input class="form-control"
                       type="text"
                       placeholder="Фамилия"
                       id="secondName"
                       v-model="secondName">
                <label for="secondName">Фамилия</label>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-6">
              <div class="form-floating">
                <input class="form-control"
                       type="text"
                       placeholder="Дата рождения"
                       id="birthDay"
                       v-model="birthDay"
                       v-mask="'##.##.####'"
                >
                <label for="birthDay">Дата рождения</label>
              </div>
            </div>
            <div class="col-6">
              <div class="form-floating">
                <input class="form-control"
                       type="text"
                       placeholder="Телефон"
                       v-model="phone"
                       id="phone"
                       @blur="v$.phone.$touch()"
                       v-mask="'+7 (###) ###-##-##'"
                >
                <label for="phone">Телефон</label>
              </div>
              <div class="error" v-for="error in v$.phone.$errors">{{error.$message}}</div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <div class="form-floating">
                <input class="form-control"
                       type="text"
                       placeholder="Адрес"
                       id="address"
                       v-model="address">
                <label for="address">Адрес</label>
              </div>
            </div>
          </div>
          <div class="jobs">
            <h2>Вакансии</h2>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-6 col-sm-6 col-md-3 my-2 d-flex align-items-center" v-for="(role, roleIndex) in roles">
                    <input class="form-check-input" v-model="role.checked"
                           :value="role.name"
                           :id="role.id" type="checkbox" >
                    <label class="form-check-label" :for="role.id">
                      {{ role.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-12 mt-4 mb-3">
              <v-select v-model="medCardSelected"
                        :options="medCard"
                        placeholder="Есть действующая санитарная книжка?"
                        :searchable="false"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12  mb-1">
              <v-select v-model="workTimeSelected"
                        :options="workTime"
                        placeholder="Подходящий для вас режим работы"
                        :searchable="false"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4  mb-3">
              <p>Поле не обязательно для ввода</p>
            </div>
          </div>
          <div class="AreYouReady">
            <h2>
              Готовы ли вы?
            </h2>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-6 my-2 d-flex align-items-center" v-for="(task, taskIndex) in tasks">
                    <input class="form-check-input" v-model="task.checked"
                           :value="task.name"
                           :id="task.id" type="checkbox" >
                    <label class="form-check-label" :for="task.id">
                      {{ task.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4 mb-4">
            <div class="col-12 col-lg-6">
              <v-select
                  class="mb-1"
                  v-model="howDoYouKnowAboutUsSelected"
                  placeholder="Как вы узнали о Япоки"
                  :options="howDoYouKnowAboutUs"
                  :searchable="false"
              />
              <p>Поле не обязательно для ввода</p>
            </div>
            <div class="col-12 col-lg-6">
              <v-select class="mb-1"
                        v-model="workPeriodSelected "
                        placeholder="На какой срок вы ищете работу"
                        :options="workPeriod"
                        :searchable="false"
              />
              <p>Поле не обязательно для ввода</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-4">
              <textarea class="form-control"
                        placeholder="Если у вас уже есть опыт работы, расскажите нам"
                        rows="10"
                        v-model="experience"
              >
        </textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <textarea class="form-control mb-1"
                        placeholder="Расскажите нам о ваших интересах"
                        rows="10"
                        v-model="aboutYourself"
              >
              </textarea>
              <p class="mb-4">Поле не обязательно для ввода</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 personalData d-flex flex-column align-items-start">
              <div class="d-flex align-items-center">
                <input class="form-check-input" v-model="personalData.checked"
                       :value="personalData.value"
                       id="personalData" type="checkbox"
                >
                <label class="form-check-label" for="personalData">
                  Согласен на <a href="https://docs.google.com/document/d/13tWvtlX5ICmmKI7BAJ5BBoBcrEdrT3o6n0XuEWy2xF0" target="_blank">обработку персональных данных</a>
                </label>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-12">
              <div class="row d-flex flex-wrap-reverse flex-sm-wrap">
                <div class="col-sm-5">
                  <router-link to="/">
                    <div class="button back">
              <span>
                <font-awesome-icon :icon="['fas', 'chevron-left']" />
              </span>

                      Назад
                    </div>
                  </router-link>
                </div>
                <div class="col-sm-7">
                  <button  type="submit" class="button send" :class="{'button-ready': ! v$.$invalid}">Отправить анкету</button>
                </div>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
    <div v-if="formSend" class="row">
      <div class="return">
        <div class="return-wrapper">
          <h1>Спасибо!</h1>
          <p>Ваша анкета отправлена.
            <br>
            Скоро с вами свяжуться.
          </p>
          <router-link class="button" :to="city ? `/?city=${city}` : '/'">Вернуться на сайт</router-link>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import { useVuelidate } from '@vuelidate/core'
import { required, email, sameAs, helpers } from '@vuelidate/validators'
import axios from "axios";
import VueMask from 'v-mask'
import { library } from '@fortawesome/fontawesome-svg-core'
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// Then add it to library
library.add(faChevronLeft)

export default {
  name: "Questionnaire",
  data(){
    return{
      cities: [
        {
          value: 'Курган',
          label: 'Курган'
        },
        {
          value: 'Сургут',
          label: 'Сургут'
        },
      ],
      city: null,
      name: '',
      secondName: '',
      birthDay: '',
      phone: '',
      address: '',
      roles:[
        {
         name: 'Сушимейкер',
         id: 'role1',
         checked: false,
        },
        {
          name: 'Пиццамейкер',
          id: 'role2',
          checked: false,
        },
        {
          name: 'Курьер',
          id: 'role3',
          checked: false,
        },
        {
          name: 'Менеджер',
          id: 'role4',
          checked: false,
        },
        {
          name: 'Заготовщик',
          id: 'role5',
          checked: false,
        },
        {
          name: 'Клинер',
          id: 'role6',
          checked: false,
        },
        {
          name: 'Кассир',
          id: 'role7',
          checked: false,
        },
        {
          name: 'Оператор',
          id: 'role8',
          checked: false,
        },
      ],
      workTime: [
        {
          label: 'полный рабочий день',
          value: 'полный рабочий день'
        },
        {
          label: 'неполный рабочий день',
          value: 'неполный рабочий день'
        },
        {
          label: 'ненормированный рабочий день',
          value: 'ненормированный рабочий день'
        },
      ],
      workTimeSelected: null,
      medCard:[
        {
          label: 'Есть',
          value: 'Есть'
        },
        {
          label: 'Нет',
          value: 'Нет'
        },
        {
          label: 'Нужно продлить',
          value: 'Нужно продлить'
        },
      ],
      medCardSelected: null,
      tasks: [
        {
          name: 'Выполнять стандарты компании',
          id: 'good_worker_Types1',
          checked: false,
        },
        {
          name: 'Работать в выходные и праздники',
          id: 'good_worker_Types2',
          checked: false,
        },
        {
          name: 'Мыть полы, посуду, сантехнику',
          id: 'good_worker_Types3',
          checked: false,
        },
        {
          name: 'Помогать убирать территорию ресторана',
          id: 'good_worker_Types4',
          checked: false,
        },
      ],
      howDoYouKnowAboutUs: [
        {
          value: 'Знакомые',
          label: 'Знакомые'
        },
        {
          value: 'Печатное издание',
          label: 'Печатное издание'
        },
        {
          value: 'Ресторан Япоки',
          label: 'Ресторан Япоки'
        },
        {
          value: 'Вконтакте',
          label: 'Вконтакте'
        },
        {
          value: 'Зарплата.ру',
          label: 'Зарплата.ру'
        },
        {
          value: 'Авито',
          label: 'Авито'
        },
        {
          value: 'Другое',
          label: 'Другое'
        },
      ],
      howDoYouKnowAboutUsSelected: null,
      workPeriod: [
        {
          value: '1 месяц',
          label: '1 месяц'
        },
        {
          value: '2-6 месяцев',
          label: '2-6 месяцев'
        },
        {
          value: '1 год',
          label: '1 год'
        },
        {
          value: '3-5 лет',
          label: '3-5 лет'
        },
        {
          value: 'Постоянно',
          label: 'Постоянно'
        },
      ],
      workPeriodSelected: null,
      experience: '',
      aboutYourself: '',
      personalData: {
        checked: false,
        value: 1,
      },
      formSend: false
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations () {
    return {
      city: { required: helpers.withMessage('Выберите город', required) },
      name: { required: helpers.withMessage('Введите имя', required)  },
      phone: { required: helpers.withMessage('Введите номер телефона  в формате +7 (999) 999-99-99', required)}
    }
  },

  created() {
    if (this.$route.query.city){
      this.city = this.cities.filter(city => city.value.toLowerCase() === this.$route.query.city.toLowerCase())
    }
  },
  beforeMount() {
    window.scrollBy(0, 0)
  },
  methods: {
    async submitForm(){
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect){
        window.scrollBy(0, 0)
        return
      }
      let data = {
        CityName: this.city ? this.city.value : 'Курган',
        FirstName: this.name,
        SecondName: this.secondName,
        Address: this.address,
        PhoneNumber: this.phone,
        Birthday: this.birthDay ? this.birthDay.split('.').reverse().join('-') : this.birthDay,
        ShiftTypes: this.workTimeSelected ? this.workTimeSelected.value : null,
        MedicalBook: this.medCardSelected ? this.medCardSelected.value : null,
        ApplicantSource: this.howDoYouKnowAboutUsSelected ?  this.howDoYouKnowAboutUsSelected.value : null,
        WorkExperience: this.experience,
        Hobby: this.aboutYourself,
        ExpectedWorkPeriod: this.workPeriodSelected ? this.workPeriodSelected.value : null,
        Oferta: this.personalData.value,
        Tasks: JSON.stringify(this.tasks.filter(task => task.checked === true).map(task => {
          return task.name
        })),
        Roles: JSON.stringify(this.roles.filter(role => role.checked === true).map(role => {
          return role.name
        }))
      }
      const bodyFormData = new FormData();
      for(const property in data){
        bodyFormData.append(property, data[property])
      }
      await axios.post('https://rabota.yapoki.ru/Reciver.php', bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" }
      }).then(response => {
        this.formSend = true
      })
    }
  }
}
</script>

<style src="./Questionnaire.scss" lang="scss" scoped />