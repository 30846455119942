<template>
  <div class="section">
    <h1>НАШИ ЦЕННОСТИ</h1>
    <div class="test2 row">
      <div class="test col-lg-6 col-sm-12">
        <div class="value-card">
          <img src="@/assets/img/values-1.webp" alt="">
          <div class="content">
            <h2>Открытость</h2>
            <p>Один из основополагающих принципов, вокруг которого строится Япоки. Наша открытость рождает огромное доверие со стороны клиентов и гостей, сотрудников и партнеров. У нас открытые кухни, бесплатные мастер-классы и экскурсии, прямые трансляции происходящего на кухне в интернет. Мы не оставляем себе право на ошибку. Такой подход заставляет нас работать качественно и с полной самоотдачей, ведь на нашу работу в любой момент могут обратить внимание клиенты.
            </p>
          </div>
        </div>
      </div>
      <div class="test col-lg-6 col-sm-12">
        <div class="value-card">
          <img src="@/assets/img/values-2.webp" alt="">
          <div class="content">
            <h2>Честность</h2>
            <p>В деловом мире, репутация - это все. Большинство людей хотят делать свою работу хорошо, и они будут счастливее, работая на кого-то с честностью, чем для того, кто их просит поступаться своими принципами. Честность в нашей компании означает производить более качественный продукт для наших клиентов.
            </p>
          </div>
        </div>
      </div>
      <div class="test col-lg-6 col-sm-12">
        <div class="value-card">
          <img src="@/assets/img/values-3.webp" alt="">
          <div class="content">
            <h2>Постоянное совершенствование</h2>
            <p>Мы постоянно развиваемся и меняемся, чтобы удовлетворять потребности рынка, сотрудников, бизнеса. Вы можете вырасти, если будете стремиться к этому. Совершенствоваться должны люди. Что бы быть успешными, конкурентными, чтобы нас не обошли, мы обязаны постоянно развиваться!
            </p>
          </div>
        </div>
      </div>
      <div class="test col-lg-6 col-sm-12">
        <div class="value-card">
          <img src="@/assets/img/values-4.webp" alt="">
          <div class="content">
            <h2>Командная работа</h2>
            <p>Для эффективного и быстрого решения задач люди объединяются в команду! У каждого участника своя продуманная и установленная роль. За ролью закрепляются границы ответственности, задачи, которые известны другим членам команды. Работа в команде предполагает взаимодействие с учетом установленных ролей.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Values"
}
</script>

<style src="./Values.scss" lang="scss" scoped />