<template>
  <div>
    <div ref="parent" class="parent">
      <div class="slider slider-canvas d-sm-none d-block">
        <slick
            ref="slick"
            :options="slickOptions">
          <div  class="canvasContainer d-sm-none" ref="containerCanvasMobile1">
            <canvas   id="canvasMobile1" ref="canvasMobile1"></canvas>
          </div>
          <div class="canvasContainer d-sm-none" ref="containerCanvasMobile2">
            <canvas   id="canvasMobile2" ref="canvasMobile2"></canvas>
          </div>
          <div class="canvasContainer d-sm-none" ref="containerCanvasMobile3">
            <canvas  id="canvasMobile3" ref="canvasMobile3"></canvas>
          </div>
          <div class="canvasContainer d-sm-none" ref="containerCanvasMobile4">
            <canvas   id="canvasMobile4" ref="canvasMobile4"></canvas>
          </div>
        </slick>
      </div>

    </div>
  </div>
</template>

<script>

import Canvas from "@/assets/js/canvas";
import Slick from  "vue-slick"
import 'slick-carousel/slick/slick.css';

export default {
  name: "GrowthMobile",
  components:{Slick},
  data(){
    return{
      slickOptions: {
        dots: true,
        arrows: false,
        slidesToShow: 1,
        // Any other options that can be got from plugin documentation
      },
      slide: null,
      parentWidth: null,
      currentPage: 0,
      loadedFont: false
    }
  },
  watch:{
    fontLoaded(){
      this.initCanvases()
    },
  },
  props: ['fontLoaded'],
  methods: {
    initCanvases(){
      this.canvasMobile1 = new Canvas(this.$refs.canvasMobile1, this.$refs.parent, this.$refs.customTooltip)
      this.canvasMobile2 = new Canvas(this.$refs.canvasMobile2, this.$refs.parent, this.$refs.customTooltip)
      this.canvasMobile3 = new Canvas(this.$refs.canvasMobile3, this.$refs.parent, this.$refs.customTooltip)
      this.canvasMobile4 = new Canvas(this.$refs.canvasMobile4, this.$refs.parent, this.$refs.customTooltip)
      this.canvasMobile1.renderMobile()
      this.canvasMobile2.renderMobile2()
      this.canvasMobile3.renderMobile3()
      this.canvasMobile4.renderMobile4()
      this.loadedFont = true
      window.onresize = () => {
        this.canvasMobile1.rerender()
        this.canvasMobile2.rerender()
        this.canvasMobile3.rerender()
        this.canvasMobile4.rerender()
      }
    },
    changePage(index){
      this.currentPage = index
      this.canvasMobile1.rerender()
      this.canvasMobile2.rerender()
      this.canvasMobile3.rerender()
      this.canvasMobile4.rerender()
    },
  }
}
</script>

<style lang="scss" scoped>
.parent{
  height: 426px;
  margin-bottom: 96px;
}
.slick-slider::v-deep{
  position: relative;
  .slick-arrow{
    z-index: 10000000;
    position: absolute;
    top: 35%;
    width: 44px;
    height: 44px;
    border-radius: 63px;
    background-color: white;
    color: white;
    overflow: hidden;
    &:before{
      content: '';
      position: absolute;
      top: 12px;
      left: 14px;
      color: black;
      width: 14px;
      height: 14px;
      //background: #333;
    }
  }
  .slick-next{
    right: 0;
    &:before {
      content: url("@/assets/img/chevron-right.svg");
    }
  }
  .slick-prev{
    &:before {
      content: url("@/assets/img/chevron-left.svg");
    }
  }
  .slick-dots{
    margin-top: 32px;
    grid-auto-flow: column;
    grid-template-columns: repeat(8, 13px);
    justify-content: center;
    margin-left: 15px;
    display: grid;
    grid-gap: 8px;
    li{
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background-color: #DCDCDC;
    }
    .slick-active{
      background-color: rgb(196, 196, 196) !important;
    }
    button{
      visibility: hidden;
    }
  }
}

</style>