<template xmlns="http://www.w3.org/1999/html">
  <div class="b-popup" v-show="show" @keydown.esc="close" @click="checkClickWithout">
    <div ref="popupContent"  class="b-popup-content container">
      <div class="b-popup-controls">
        <div class="b-popup-controls__prev" @click="prevVideo"></div>
        <div class="b-popup-controls__next" @click="nextVideo"></div>
      </div>
      <div class="b-popup-timeline">
        <span :class="{'b-popup-timeline-viewed': videos[0].viewed, 'b-popup-timeline-default': !videos[0].viewed}" ref="span1" @click="selectVideo(0)"></span>
        <span :class="{'b-popup-timeline-viewed': videos[1].viewed, 'b-popup-timeline-default': !videos[1].viewed}" ref="span2" @click="selectVideo(1)"></span>
        <span :class="{'b-popup-timeline-viewed': videos[2].viewed, 'b-popup-timeline-default': !videos[2].viewed}" ref="span3" @click="selectVideo(2)"></span>
        <span :class="{'b-popup-timeline-viewed': videos[3].viewed, 'b-popup-timeline-default': !videos[3].viewed}" ref="span4" @click="selectVideo(3)"></span>
      </div>
      <span @click="close" class="b-popup-close"></span>
      <video ref="video"  playsinline>
        <source :src="src" :type="'video/mp4'">
      </video>
    </div>
  </div>
</template>

<script>
import video1 from "@/assets/video/Юлия менеджер.mp4";
import video2 from "@/assets/video/Женя Курьер 9х16.mp4";
import video3 from "@/assets/video/Ольга кассир.mp4";
import video4 from "@/assets/video/Арина Пиццамейкер 9х16.mp4";

export default {
  name: "PopUpVideo",
  data(){
    return{
      src: '',
      playing: false,
      videoLoaded: false,
      videos: [
        {
          src: video1,
          index: 0,
          viewed: false
        },
        {
          src: video2,
          index: 1,
          viewed: false
        },      {
          src: video3,
          index: 2,
          viewed: false
        },      {
          src: video4,
          index: 3,
          viewed: false
        }
      ]
    }
  },
  watch:{
    indexVideo(newValue){
      if (this.show){

        this.play()
      }
    },
    show(newValue, oldValue){
      if (newValue === true){
        this.play()
      } else {
        this.pause()
      }
    },
  },
  props: {
    show:{
      default: false,
      require: true,
      type: Boolean
    },
    indexVideo:{
      type: Number,
      require: true,
      default: -1
    },
  },

  methods: {
    nextVideo(){
      this.pause()
      if (this.indexVideo + 1 <= 3){
        this.videos[this.indexVideo].viewed = true
        this.$emit('changeIndexVideo', this.indexVideo + 1)
      } else {
        this.$emit('changeIndexVideo', 0)
      }

    },
    prevVideo(){
      this.pause()
      if (this.indexVideo - 1 >= 0){
        this.videos[this.indexVideo].viewed = true
        this.$emit('changeIndexVideo', this.indexVideo - 1)
      } else {
        this.$emit('changeIndexVideo', 3)
      }
    },
    selectVideo(index){
      this.pause()
      this.$emit('changeIndexVideo', index)
    },
    play() {
      if (this.indexVideo !== -1){
        this.src = this.videos.find(video => video.index === this.indexVideo).src
      }
      this.$refs.video.pause()
      this.$refs.video.load()
      this.$refs.video.play().then(() => {
        this.setPlaying(true)
        this.$refs[`span${this.indexVideo + 1}`].style.transition = `all ${this.$refs.video.duration + 0.5}s ease-out`
        setTimeout(() => {
          this.$refs[`span${this.indexVideo  + 1}`].classList.add('b-popup-timeline-start')
        }, 500)
      }).catch(e => {
        console.log(e)})

    },

    pause() {
      this.$refs[`span${this.indexVideo + 1}`].style.transition = 'none'
      this.$refs[`span${this.indexVideo  + 1}`].classList.remove('b-popup-timeline-start')
      this.videos[this.indexVideo].viewed = true
      if (this.playing){
        this.$refs.video.pause();
        this.setPlaying(false);
      }
    },

    setPlaying(state) {
      this.playing = state;
    },

    close(){
      this.$emit('close', false)
    },
    checkClickWithout(e){
      const withinBoundaries = e.composedPath().includes(this.$refs.popupContent);
      if ( ! withinBoundaries ) {
        this.close()
      }
    }
  }
}
</script>

<style lang="scss" src="./PopUpVideo.scss" scoped />
