import { render, staticRenderFns } from "./Head.vue?vue&type=template&id=264a828e&scoped=true&"
import script from "./Head.vue?vue&type=script&lang=js&"
export * from "./Head.vue?vue&type=script&lang=js&"
import style0 from "./Head.scss?vue&type=style&index=0&id=264a828e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "264a828e",
  null
  
)

export default component.exports