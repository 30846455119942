<template>
  <div class="app">
    <div class="container">
      <Toplogo/>
      <router-view></router-view>
    </div>
    <Footer/>

  </div>
</template>

<script>

export default {
  name: 'App',
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || 'Работа япоки';
      }
    },
  }
}
</script>

<style lang="scss">
.app{
  @media (max-width: 575.98px) {
    overflow: hidden;
  }
}
</style>
