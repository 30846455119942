<template>
  <div class="container-fluid">
    <div class="footer">
      <div class="container">
        <div class="pc">
          <div class="row align-items-center footer-up">
            <div class="col-xs-10 col-sm-10 col-md-9 col-lg-9">
              <div class="row align-items-center">
                <div class="col-lg-5 col-md-5 col-sm-5  footer-phone">
                  <a href="tel:8-800-200-200-7">
                <span>
                 8 800 200-200-7
                </span>
                  </a>
                  Звонок бесплатный
                </div>
                <div class="col-lg-7 col-md-7 col-sm-7">
                  <ul class="nav">
                    <li><a href="https://yapoki.ru/">Сайт доставки</a></li>
                    <li><a href="https://yapoki.ru/kurgan/about/contacts/">Контакты</a></li>
                    <li><a href="https://yapokifranchise.ru/">Франшиза</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-xs-2 col-sm-2 col-md-3 col-lg-3">
              <div class="vk">
                <a href="https://vk.com/yapoki">
                  <font-awesome-icon :icon="['fab', 'vk']"
                                     style="color: #fff; border: 1px solid white; width: 40px; height: 40px; padding: 8px 9px 9px 8px; border-radius: 10px;"/>
                </a>
              </div>
            </div>
          </div>
          <div class="row align-items-center footer-down">
            <div class="col">
              <ul class="footer-politics">
                <li>© 2023 Япоки</li>
                <li><a href="https://docs.google.com/document/d/13tWvtlX5ICmmKI7BAJ5BBoBcrEdrT3o6n0XuEWy2xF0">Политика обработки персональных данных</a></li>
              </ul>
            </div>
            <div class="col">
              <div class="feedback">
                <a href="mailto:feedback@yapoki.ru">feedback@yapoki.ru</a>
              </div>
            </div>
          </div>
        </div>
        <div class="mobile">
          <div class="row">
            <div class="col d-flex flex-column align-items">
              <div class="feedback">
                <a href="mailto:feedback@yapoki.ru">feedback@yapoki.ru</a>
              </div>
              <div class="footer-phone">
                <a href="tel:8-800-200-200-7">
                <span>
                 8 800 200-200-7
                </span>
                </a>
                Звонок бесплатный
                <hr>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <ul class="nav d-flex flex-column align-items">
                <li><a href="https://yapoki.ru/">Сайт доставки</a></li>
                <li><a href="https://yapoki.ru/kurgan/about/contacts/">Контакты</a></li>
                <li>Франшиза</li>
              </ul>
              <hr>
            </div>
          </div>
          <div class="row">
            <div class="col d-flex flex-column">
              <div class="vk">
                <a class="d-flex align-items-center" href="https://vk.com/yapoki">
                  <font-awesome-icon :icon="['fab', 'vk']"
                                     style="color: #fff; width: 40px; height: 40px; padding: 2px; border-radius: 10px;"/>
                  <span>Вконтакте</span>
                </a>
              </div>
              <ul class="footer-politics">
                <li><a href="https://docs.google.com/document/d/13tWvtlX5ICmmKI7BAJ5BBoBcrEdrT3o6n0XuEWy2xF0" target="_blank">Политика обработки персональных данных</a></li>
                <li>© 2023 Япоки</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import { library } from '@fortawesome/fontawesome-svg-core'
import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons";
import {faVk} from "@fortawesome/free-brands-svg-icons"
// Then add it to library
library.add(faVk)
export default {
  name: "Footer"
}

</script>

<style src="./Footer.scss" lang="scss" scoped />
