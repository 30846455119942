import Head from "@/components/Head/Head";
import Roles from "@/components/Roles/Roles";
import Info from "@/components/Info/Info";
import Values from "@/components/Values/Values";
import Growth from "@/components/Growth/Growth";
import Guarantees from "@/components/Guarantees/Guarantees";
import JoinUs from "@/components/JoinUs/JoinUs";
import Footer from "@/components/Footer/Footer";
import Banner from "@/components/Banner/Banner";
import Main from "@/components/Main";
import Questionnaire from "@/components/Questionnaire/Questionnaire";
import Toplogo from "@/components/Top-logo/Top-logo";
import GrowthMobile from "@/components/GrowthMobile";
import PopUpVideo from "@/components/PopUpVideo/PopUpVideo";
import FontLoader from "@/components/FontLoader";

export default {
    Head,
    Roles,
    Info,
    Values,
    Growth,
    Guarantees,
    JoinUs,
    Footer,
    Banner,
    Main,
    Questionnaire,
    Toplogo,
    GrowthMobile,
    PopUpVideo,
    FontLoader
}