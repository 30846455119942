<template>
  <div>
    <Head :city="city"/>
    <Banner :style="{opacity: isBannerVisable ? 1 : 0}" ref="banner" :city="city"/>
    <Roles ref="roles"/>
    <Info ref="info"/>
    <Values/>
    <Growth :fontLoaded="fontLoaded"/>
    <Guarantees ref="guarantees" @positionChange="changePosition"/>
    <JoinUs :city="city" ref="join"/>
    <font-loader @font-loaded="onFontLoaded"></font-loader>
  </div>
</template>

<script>
export default {
  name: "Main",
  data(){
    return{
      p: null,
      isBannerVisable: false,
      city: null,
      fontLoaded: false,
    }
  },
  methods:{
    onFontLoaded(){
      this.fontLoaded = true
    },
    changePosition(position){
      this.p = position
    },

  },
  created() {
    if (this.$route.query.city){
      this.city = this.$route.query.city
    }
  },
  mounted() {

    let self = this
    document.addEventListener('scroll', function() {

      if (self.$refs['roles'] && self.$refs['roles'].$el && self.$refs['guarantees'] && self.$refs['guarantees'].$el){
        const posRoles = self.$refs['roles'].$el.getBoundingClientRect().bottom
        if (posRoles > 0){
          self.isBannerVisable = false
        }
        if (posRoles <= 0  && self.p >= 0){
          self.isBannerVisable = true
        }
        if (self.p < 0){
          self.isBannerVisable = false
        }
      }

    })
  }
}
</script>

<style scoped>

</style>