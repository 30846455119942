<template>
  <div class="row">
    <div class="col">
      <router-link class="logo-link" to="/"><img class="logo" src="@/assets/logo.png"></router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Toplogo"
}
</script>

<style src="./Top-logo.scss" lang="scss" scoped />

