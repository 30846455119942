<template>
<div class="section roles">
  <div class="row">
    <div class="col">
      <p class="title">Ищем сушимейкеров, пиццамейкеров, курьеров, менеджеров, заготовщиков, клинеров, кассиров и операторов</p>
    </div>
    <div class="slider">
      <slick
          class="d-block d-md-none"
          ref="slick"
          :options="slickOptions"
          @beforeChange="handleBeforeChange"
      >
        <div class="row justify-content-center">
          <div class="col">
            <div class="role-card">
              <img src="@/assets/img/roles-1.webp" alt="">
              <h2>Сушимейкер</h2>
              <p>Готовит вкусные роллы, поддерживает чистоту на рабочем месте и дружелюбную атмосферу в команде</p></div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col">
            <div class="role-card">
              <img src="@/assets/img/roles-2.webp" alt="">
              <h2>Пиццамейкер</h2>
              <p>Готовит вкусные пиццы и закуски, поддерживает чистоту на рабочем месте и дружелюбную атмосферу в команде</p></div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col">
            <div class="role-card">
              <img src="@/assets/img/roles-3.webp" alt="">
              <h2>Курьер</h2>
              <p>Занимается доставкой готовых продуктов и хорошего настроения клиентам Япоки</p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col">
            <div class="role-card">
              <img src="@/assets/img/roles-4.webp" alt="">
              <h2>Менеджер смены</h2>
              <p>Организует работу, решает вопросы сотрудников и гостей, задает рабочий настрой для команды</p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col">
            <div class="role-card">
              <img src="@/assets/img/roles-5.webp" alt="">
              <h2>Заготовщик</h2>
              <p>Помогает поварам делать заготовки и следить за чистотой на кухне</p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col">
            <div class="role-card">
              <img src="@/assets/img/roles-6.webp" alt="">
              <h2>Клинер</h2>
              <p>Поддерживает чистоту на кухне и в зале ресторана</p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col">
            <div class="role-card">
              <img src="@/assets/img/roles-7.webp" alt="">
              <h2>Кассир</h2>
              <p>Встречает гостей и радует их улыбкой, принимает и выдает заказы, следит за чистотой и приятной атмосферой в ресторане</p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col">
            <div class="role-card">
              <img src="@/assets/img/roles-8.webp" alt="">
              <h2>Оператор</h2>
              <p>Всегда на связи. Оформляет заказы и отвечает на вопросы наших клиентов</p>
            </div>
          </div>
        </div>
      </slick>
    </div>
    <div class="row d-none d-md-flex">
      <div class="col-md-6 col-lg-3">
        <div class="role-card">

          <img src="@/assets/img/roles-1.webp" alt="">
          <h2>Сушимейкер</h2>
          <p>Готовит вкусные роллы, поддерживает чистоту на рабочем месте и дружелюбную атмосферу в команде</p></div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="role-card">
          <img src="@/assets/img/roles-2.webp" alt="">
          <h2>Пиццамейкер</h2>
          <p>Готовит вкусные пиццы и закуски, поддерживает чистоту на рабочем месте и дружелюбную атмосферу в команде</p></div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="role-card">
          <img src="@/assets/img/roles-3.webp" alt="">
          <h2>Курьер</h2>
          <p>Занимается доставкой готовых продуктов и хорошего настроения клиентам Япоки</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="role-card">
          <img src="@/assets/img/roles-4.webp" alt="">
          <h2>Менеджер смены</h2>
          <p>Организует работу, решает вопросы сотрудников и гостей, задает рабочий настрой для команды</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="role-card">
          <img src="@/assets/img/roles-5.webp" alt="">
          <h2>Заготовщик</h2>
          <p>Помогает поварам делать заготовки и следить за чистотой на кухне</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="role-card">
          <img src="@/assets/img/roles-6.webp" alt="">
          <h2>Клинер</h2>
          <p>Поддерживает чистоту на кухне и в зале ресторана</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="role-card">
          <img src="@/assets/img/roles-7.webp" alt="">
          <h2>Кассир</h2>
          <p>Встречает гостей и радует их улыбкой, принимает и выдает заказы, следит за чистотой и приятной атмосферой в ресторане</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="role-card">
          <img src="@/assets/img/roles-8.webp" alt="">
          <h2>Оператор</h2>
          <p>Всегда на связи. Оформляет заказы и отвечает на вопросы наших клиентов</p>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import { library } from '@fortawesome/fontawesome-svg-core'
import Vue from "vue";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Slick from  "vue-slick"
import 'slick-carousel/slick/slick.css';
// Then add it to library
library.add(faChevronLeft, faChevronRight)

export default {
  name: "Roles",
  data(){
    return{
      slickOptions: {
        arrows: true,
        dots: true,
        responsive: [
          {
            breakpoint: 576,
            settings: {
              centerMode: true,
              centerPadding: '22px',
              slidesToShow: 1,
            }
          },
        ],
        centerMode: true,
        centerPadding: '130px',
        slidesToShow: 1,

        // Any other options that can be got from plugin documentation
      },
      prevButton: false,

    }
  },
  components: {Slick},
  methods:{
    handleBeforeChange(){
      let prevButton = document.getElementsByClassName('slick-prev')[0]
      prevButton.style.display = 'block'
    }
  },
  mounted() {
    let prevButton = document.getElementsByClassName('slick-prev')[0]
    prevButton.style.display = 'none'
  }
}
</script>

<style src="./Roles.scss" lang="scss" scoped />