<template>
</template>



<script>
//https://github.com/typekit/webfontloader
import WebFontLoader from 'webfontloader';
export default {
  name: 'FontLoader',
  created() {
    WebFontLoader.load({
      custom: {
        // Use FVD notation to include families https://github.com/typekit/fvd
        families: ['M PLUS Rounded 1c'],
        // Path to stylesheet that defines font-face
        urls: ['./fonts.css'],
      },
      active: this.setFontLoaded,
    });
  },
  methods: {
    setFontLoaded() {
      this.$emit('font-loaded');
    },
  },
};
</script>