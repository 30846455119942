<template>
  <div class="section">
    <h1>
      МЫ ГАРАНТИРУЕМ
    </h1>
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-4">
        <div class="column column-top column-one">
          <img src="@/assets/img/guarantees1.webp" alt="">
          <h2>Официальное трудоустройство</h2>
          <p>Оформление по всем правилам ТК и своевременная зарплата</p>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4">
        <div class="column column-top column-two">
          <h2>Корпоративные бонусы</h2>
          <p>Выдаем современную униформу и кормим вкусными обедами</p>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-4">
        <div class="column column-top column-three">
          <h2>Работа недалеко от дома или учёбы</h2>
          <p>Рестораны в удобных районах города</p>
          <div class="image">
            <img src="@/assets/img/guarantees2.webp">
            <div class="image-shadow">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-9">
        <div class="column column-four">
          <h2>Удобный и гибкий график</h2>
          <p ref="gp">Подберем удобный график работы, баланс работы и отдыха, так как считаем, нужно хорошо работать и хорошо отдыхать!</p>
        </div>
        <div class="column column-five">
          <h2>Всему научим!</h2>
          <div>
            <p>Опыт не имеет значения. У нас есть опытные наставники, которые помогут влиться в работу быстро уже с первых дней</p>
            <div class="image">
              <img src="@/assets/img/guarantees3.webp">
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-12">
        <div class="column column-six">
          <h2>Возможность карьерного роста</h2>
          <p>Можно вырасти из стажера до менеджера всего за полгода работы!</p>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Guarantees",
  mounted() {
    let self = this
    document.addEventListener('scroll', function() {
      if (self.$refs.gp){
        self.$emit('positionChange', self.$refs['gp'].getBoundingClientRect().top)
      }

    })
  }
}
</script>

<style src="./Guarantees.scss" lang="scss" scoped />