<template>
<div class="section" style="position: relative">


  <h1 class="d-block d-sm-none">
    КАРЬЕРНЫЙ РОСТ
  </h1>
  <div class="growth">
    <h1 class="d-none d-sm-block">
      КАРЬЕРНЫЙ РОСТ
    </h1>
    <GrowthMobile :fontLoaded="fontLoaded"></GrowthMobile>
    <div class="canvas_container d-none d-sm-block" ref="parent">
      <div ref="customTooltip" class="tooltip b-tooltip custom-tooltip">
        <div class="arrow"></div>
        <div class="tooltip-inner"></div>
      </div>
      <div class="canvasContainer d-none d-sm-block" ref="containerCanvasPC">
        <canvas id="canvasPC" ref="canvasPC"></canvas>
      </div>

    </div>
  </div>
  <slick

      ref="slick"
      :options="slickOptions"
      style="margin-top: 40px">
      <div class="slide" :class="{'slide-current': this.feedbackPageIndex === 0}">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-3 d-flex justify-content-center align-items-start" >
            <img class="slide-img" src="@/assets/img/Growth-slide2.webp" alt="">
          </div>
          <div class="col-lg-8 col-md-8 col-sm-9">
            <div class="slide-text">
              <h2>
                <span>Георгий</span>, Инструктор-сушимейкер
              </h2>
              <p>
                Я пришел работать в Япоки сразу после школы и тут понеслось... Сейчас даже не представляю работу в другом месте. Тут меня всему (готовить и убираться) научили буквально с нуля, одним словом, научили меня работать. Мне захотелось обучать таких же простых ребят как я, поэтому стал инструктором. Еще хочу попробовать себя в роли менеджера. В Япоки есть такие возможности – пробовать себя и развиваться в том направлении, которое нравится.
              </p>
            </div>
            <div class="slide-location">
              <font-awesome-icon :icon="['fas', 'map-marker-alt']" :style="{color: '#A42F84', fill: '#A42F84'}" :size="'lg'"/>
              Курган
            </div>
          </div>
        </div>
      </div>
      <div class="slide" :class="{'slide-current': this.feedbackPageIndex === 1}">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-3 d-flex justify-content-center align-items-start"><img class="slide-img" src="@/assets/img/Growth-slide1.webp" alt=""></div>
          <div class="col-lg-8 col-md-8 col-sm-9">
            <div class="slide-text">
              <h2>
                <span>Женя</span>, менеджер
              </h2>
              <p>
                Люблю Япоки, потому что именно здесь открываются возможности как для личного, так и для карьерного роста.
              </p>
              <p>
                По образованию я – учитель, мне нравится работать с людьми, обучать и развивать. В Япоки я прошел путь от сушимейкера до менеджера, работал инструктором. И понимаю, что это не предел! Работа в Япоки, в хорошем коллективе мотивирует расти и развиваться дальше!
              </p>
            </div>
            <div class="slide-location">
              <font-awesome-icon :icon="['fas', 'map-marker-alt']" :style="{color: '#A42F84', fill: '#A42F84'}" :size="'lg'"/>
              Курган
            </div>
          </div>
        </div>
      </div>
      <div class="slide" :class="{'slide-current': this.feedbackPageIndex === 2}">
        <div class="row">
          <div class="col-sm-3 col-md-4 col-lg-4 d-flex justify-content-center align-items-start"><img class="slide-img" src="@/assets/img/Growth-slide3.webp" alt=""></div>
          <div class="col-sm-9 col-md-8 col-lg-8 ">
            <div class="slide-text">
              <h2>
                <span>Ольга</span>, кассир
              </h2>
              <p>
                Я работаю кассиром в Япоки, пришла на должность пиццамейкера, но поняла, что хочу попробовать себя не только в приготовлении пицц и закусок. И компания дала мне такую возможность, поэтому я стала кассиром.


              </p>
              <p>
                Параллельно я учусь на менеджера, поэтому работа здесь помогает мне и в образовании, ведь я проверяю все гипотезы на практике. В будущем я вижу себя на должности менеджера одного из ресторанов Япоки.
              </p>
            </div>
            <div class="slide-location">
              <font-awesome-icon :icon="['fas', 'map-marker-alt']" :style="{color: '#A42F84', fill: '#A42F84'}" :size="'lg'"/>
              Курган
            </div>
          </div>
        </div>
      </div>
  </slick>
</div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";
import Slick from  "vue-slick"
import Canvas from "@/assets/js/canvas";
import 'slick-carousel/slick/slick.css';
library.add(faMapMarkerAlt)

export default {

  name: "Growth",
  components: { Slick },
  data(){
    return{
      slickOptions: {
        arrows: false,
        responsive: [
          {
            breakpoint: 576,
            settings: {
              centerMode: true,
              centerPadding: '22px',
              slidesToShow: 1,
            }
          },
        ],
              centerMode: true,
              centerPadding: '130px',
        slidesToShow: 1,
        // Any other options that can be got from plugin documentation
      },
      slide: null,
      parentWidth: null,
      currentPage: 0,
      feedbackPageIndex: 0,
    }
  },
  watch:{
    fontLoaded(){
      this.initCanvas()
    },
  },
  props: ['fontLoaded'],
  methods: {
    initCanvas(){
      this.canvasPC = new Canvas(this.$refs.canvasPC, this.$refs.parent, this.$refs.customTooltip)
      this.canvasPC.renderPc()
      window.onresize = () => {
        this.canvasPC.rerender()
      }
    },
    changePage(index){
      this.currentPage = index
    },
  },
}
</script>

<style lang="scss" src="./Growth.scss" scoped />
